export const environment = {
  name: "test",
  production: false,
  baseURL: "https://sasbulaptops-api-test.azure.chevron.com",
  UIbaseURL: "https://sasbulaptops-test.azure.chevron.com",
  clientId: "e88c2b1d-a2f8-4380-9002-b8fd82b38374",
  tenantId: "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  endpointScopes: ['e88c2b1d-a2f8-4380-9002-b8fd82b38374/.default'],
  appInsights: {
    instrumentationKey: "dcb1faba-08f1-4203-b44b-dccc4c684cd3"
}
};
